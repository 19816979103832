import React, { useEffect, useRef, useState } from 'react';
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { Grid, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, IconButton, ListItemIcon, ListItemText, Input, FormControlLabel, Radio, RadioGroup, FormLabel, Tab } from '@mui/material';
import { image } from "../../../src/common/Theme";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Apiconnection from '../../utils/Apiconnection';
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js';

const BookingReceipts = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [invoiceDetails, setInvoiceDetails] = useState(null); // State for invoice details


   
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    const printableRef = useRef();

    const [value, setValue] = React.useState('1');
    const [age, setAge] = React.useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const payload = location.state;
    console.log(payload)

    const handleViewInvoice = async () => {
        // navigate('/booking-receipts', { state: id });
        try {
            const response = await Apiconnection.post('invoice-detail', { "bookingRequestId": payload });
            console.log('invoice-detail', response)
            if (response.data && response.data.status) {
                setInvoiceDetails(response.data.data); 
                console.log('invoiceDetails',invoiceDetails)
            } else {
                console.error('No data found in response:', response.data);
            }
        } catch (error) {
            console.error('Error fetching Invoice details:', error);
        }
    }

    const fetchPolicies = async () => {
        try {
            const response = await Apiconnection.post('get-policies');
            if (response.data && response.data.data && response.data.data.policies) {
                setPolicies(response.data.data.policies);
            } else {
                console.error('Unexpected response structure:', response.data);
                setPolicies('');
            }
        } catch (error) {
            console.error('Error fetching policies:', error);
            setPolicies('');
        }
    };

    useEffect(() => {
        handleViewInvoice();
        fetchPolicies();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handlePrint = () => {
        const element = printableRef.current;
        const opt = {
            margin:       1,
            filename:     'booking_receipt.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'in', format: 'A4', orientation: 'landscape' }
        };

        html2pdf().from(element).set(opt).save();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div className='grid-container'>
           <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <div className='inner-container'>
                <div className="booking-receipts-sec">
                    <div className='receipt-filter'>
                        <h5></h5>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" onClick={handleBack} style={{ marginRight: '10px' }}>Back</Button>
                        <Button variant="contained" onClick={handlePrint}>Print</Button>
                        </div>
                        {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">Select For Print</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={age}
                                label="Age"
                                onChange={handleAgeChange}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                <MenuItem value={10}>Office</MenuItem>
                                <MenuItem value={20}>Customer</MenuItem>

                            </Select>
                        </FormControl> */}
                    </div>

                    <Box sx={{ width: '100%', typography: 'body1' }} ref={printableRef}>
                        <TabContext value={value}>
                            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="All" value="1" />
                                    <Tab label="Office's" value="2" />
                                    <Tab label="Customer's" value="3" />

                                </TabList>
                            </Box> */}
                            <div className='table-responsive'>
                            <table className='top-table' style={{ width: '100%', backgroundColor: '#fff', padding: '40px', margin: '0px' }}>
                                <tr>
                                    <td className="cmn-width">
                                        <table className="common-table">
                                            <tr>
                                                <td style={{ padding: '0' }}>
                                                    <div className="bg-black">
                                                        <h5>Prime Park Receipt</h5>
                                                        <p>{invoiceDetails?.invoiceDetail?.bookingRequest?.customerMobile}</p>
                                                        <p>{invoiceDetails?.invoiceDetail?.bookingRequest?.user?.address}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table className="body-top">
                                                        <tr>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Check In Date & Time</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.fromDate} | {invoiceDetails?.invoiceDetail?.bookingRequest?.selectedCheckInTime}</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Check Out Date & Time</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.toDate} | {invoiceDetails?.invoiceDetail?.bookingRequest?.selectedCheckOutTime}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Name</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.user?.firstName} {invoiceDetails?.invoiceDetail?.bookingRequest?.user?.lastName}</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Phone number</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.user?.phone}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Ticket ID</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.bookingTicketNo}</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Slot Number</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.slot?.slotName}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Payment</p>
                                                                    <h3>Cash</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="check-in">
                                                                    <p>Cash</p>
                                                                    <h3>$ {invoiceDetails?.invoiceDetail?.payableAmount}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <table className="biling-details">
                                                        <tr>
                                                            <td>
                                                                <h2>Billing Details</h2>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>Onsite amount charges</p>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.onSiteChargeForVendor}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="parking-carge">
                                                                    <p>Parking Charges</p>
                                                                    {/* <span>(13 days * $30 -{invoiceDetails?.bookingRequest?.vehicleType?.typeName})</span> */}
                                                                    <span>({invoiceDetails?.invoiceDetail?.bookingRequest?.vehicleType?.typeName})</span>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.parkingChargeTotal}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="parking-carge">
                                                                    <p>Overstay charges</p>
                                                                    <span>({invoiceDetails?.invoiceDetail?.overstayDays} days)</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.overstayAmountTotal}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="parking-carge">
                                                                    <p>Early Check In charges</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.earlyCheckinAmountTotal}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="parking-carge">
                                                                    <p>Extra Passenger Fees</p>
                                                                    <span>({invoiceDetails?.invoiceDetail?.extraPassengers} Passengers)</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.extraPassengerChargesTotal}</h5>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div className="parking-carge">
                                                                    <p>Reservation Fee (Paid)</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.reservationAmount}</h5>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td>
                                                    <table className="bottom-table">
                                                        <tr className="bg-black">
                                                            <td style={{ width: '50%' }}>
                                                                <h5 style={{ textAlign: 'left' ,fontSize:'10px', lineHight: '10px' }}>Total amount to pay</h5>
                                                            </td>
                                                            <td style={{ width: '50%' }}>
                                                                <h5 style={{ textAlign: 'right',fontSize:'10px', lineHight: '10px' }}>$ {invoiceDetails?.invoiceDetail?.dueAmount}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <h4>Terms & Conditions</h4>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <div   dangerouslySetInnerHTML={{ __html: policies }} /> {/* Render policies HTML */}
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td colSpan="2">
                                                                <p>1. Your reservation will be subject to Parking hourly and
                                                                    daily rate as soon as the voucher is expired. The hourly rate is
                                                                    10/hour and the daily rate is $15 for small cars and $18 for
                                                                    SUV/ Trucks per day. </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    2.This facility does NOT allow in/out privileges. You CANNOT enter & exit
                                                                    more than once.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    3. For all Cancelled online vouchers/Reservation customers are
                                                                    required to pay for one day of parking and a $10 service fee.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    4. This facility
                                                                    does not allow online reservation extensions. Additional time must be paid
                                                                    on-site at a regular rate.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    5. Customer is required to take pictures of their
                                                                    vehicles (all sides) at the location during drop-off and also agree that no
                                                                    damage claim can be filed without providing those pictures.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    6. Customer must
                                                                    leave the car key to the attendant and agree that failure to do so may
                                                                    result
                                                                    to towing fees from $75 to $150.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    7. Customer agree that all balance must be paid
                                                                    in full prior to retrieval of vehicle.</p>
                                                            </td>
                                                        </tr> */}

                                                        <tr className="Signature">
                                                            <td style={{ verticalAlign: 'middle', width: '10%' }}>
                                                                <div className="check-img">
                                                                    {/* <img src="images/check.png" alt=""> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h4>I accept the Terms & Conditions</h4>
                                                            </td>
                                                        </tr>
                                                        <tr className="Signature">
                                                            <td></td>
                                                            <td>
                                                                <h4>Customer's Signature</h4>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td></td>
                                                            <td>...................................................</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>

                                    <td class="cmn-width">
                                        <table class="common-table">
                                            <tr>
                                                <td style={{ padding: '0' }}>
                                                    <div class="bg-black">
                                                        <h5>Prime Park Receipt</h5>
                                                        <p>{invoiceDetails?.invoiceDetail?.bookingRequest?.customerMobile}</p>
                                                        <p>{invoiceDetails?.invoiceDetail?.bookingRequest?.user?.address}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table class="body-top">
                                                        <tr>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Check In Date & Time</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.fromDate} | {invoiceDetails?.invoiceDetail?.bookingRequest?.selectedCheckInTime}</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Check Out Date & Time</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.toDate} | {invoiceDetails?.invoiceDetail?.bookingRequest?.selectedCheckOutTime}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Name</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.user?.firstName} {invoiceDetails?.invoiceDetail?.bookingRequest?.user?.lastName}</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Phone number</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.user?.phone}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Ticket ID</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.bookingTicketNo}</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Slot Number</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.slot?.slotName}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Payment</p>
                                                                    <h3>Cash</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Cash</p>
                                                                    <h3>$ {invoiceDetails?.invoiceDetail?.payableAmount}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <table class="biling-details">
                                                        <tr>
                                                            <td>
                                                                <h2>Billing Details</h2>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>Onsite amount charges</p>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.onSiteChargeForVendor}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="parking-carge">
                                                                    <p>Parking Charges</p>
                                                                    {/* <span>(13 days * $30 - Truck )</span> */}
                                                                    <span>({invoiceDetails?.invoiceDetail?.bookingRequest?.vehicleType?.typeName})</span>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.parkingChargeTotal}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="parking-carge">
                                                                    <p>Overstay charges</p>
                                                                    <span>({invoiceDetails?.invoiceDetail?.overstayDays} days)</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.overstayAmountTotal}</h5>
                                                            </td>
                                                        </tr>
                                                                                                                <tr>
                                                            <td>
                                                                <div className="parking-carge">
                                                                    <p>Early Check In charges</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.earlyCheckinAmountTotal}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="parking-carge">
                                                                    <p>Extra Passenger Fees</p>
                                                                    <span>({invoiceDetails?.invoiceDetail?.extraPassengers} Passengers)</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.extraPassengerChargesTotal}</h5>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div className="parking-carge">
                                                                    <p>Reservation Fee (Paid)</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5>$ {invoiceDetails?.invoiceDetail?.reservationAmount}</h5>
                                                            </td>
                                                        </tr>
                                                        
                                                    </table>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td>
                                                    <table class="bottom-table">
                                                        <tr class="bg-black">
                                                            <td style={{ width: '50%' }}>
                                                                <h5 style={{ textAlign: 'left' ,fontSize:'10px', lineHight: '10px' }}>Total amount to pay</h5>
                                                            </td>
                                                            <td style={{ width: '50%' }}>
                                                            <h5 style={{ textAlign: 'right',fontSize:'10px', lineHight: '10px' }}>$ {invoiceDetails?.invoiceDetail?.dueAmount}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <h4>Terms & Conditions</h4>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <div dangerouslySetInnerHTML={{ __html: policies }} />
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td colSpan="2">
                                                                <p>1. Your reservation will be subject to Parking hourly and
                                                                    daily rate as soon as the voucher is expired. The hourly rate is
                                                                    10/hour and the daily rate is $15 for small cars and $18 for
                                                                    SUV/ Trucks per day. </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    2.This facility does NOT allow in/out privileges. You CANNOT enter & exit
                                                                    more than once.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    3. For all Cancelled online vouchers/Reservation customers are
                                                                    required to pay for one day of parking and a $10 service fee.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    4. This facility
                                                                    does not allow online reservation extensions. Additional time must be paid
                                                                    on-site at a regular rate.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    5. Customer is required to take pictures of their
                                                                    vehicles (all sides) at the location during drop-off and also agree that no
                                                                    damage claim can be filed without providing those pictures.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    6. Customer must
                                                                    leave the car key to the attendant and agree that failure to do so may result
                                                                    to towing fees from $75 to $150.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    7. Customer agree that all balance must be paid
                                                                    in full prior to retrieval of vehicle.</p>
                                                            </td>
                                                        </tr> */}

                                                        <tr>
                                                            <td colSpan="2" style={{ textAlign: 'center' }}>
                                                                <div class="scnr-img">
                                                                    {/* <img src="images/scnr.png" alt=""> */}
                                                                </div>
                                                            </td>

                                                        </tr>

                                                        {/* <!-- 
                                <tr>
                                    <td></td>
                                    <td >...................................................</td>
                                </tr> --> */}
                                                    </table>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>

                                    <td class="cmn-width">
                                        <table class="common-table">
                                            <tr>
                                                <td style={{ padding: '0' }}>
                                                    <div class="bg-black">
                                                        <h5>Prime Park JFK</h5>
                                                        {/* <p>+918298738900</p>
                                                        <p>5115 Lefferts Blvd South Ozone Park NY 11420</p> */}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table class="body-top">
                                                        <tr>
                                                            <td>
                                                                <div class="slot">
                                                                    <p>Slot</p>
                                                                    <h2>{invoiceDetails?.invoiceDetail?.bookingRequest?.slot?.slotName}</h2>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="slot">
                                                                    <p>Pickup : Date</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.toDate}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Name</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.user?.firstName} {invoiceDetails?.invoiceDetail?.bookingRequest?.user?.lastName}</h3>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="check-in">
                                                                    <p>Phone number</p>
                                                                    <h3>{invoiceDetails?.invoiceDetail?.bookingRequest?.customerMobile}</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div class="slot bg-white">
                                                                    <p>Reservation ID</p>
                                                                    <h3 style={{textAlign: 'center'}}>{invoiceDetails?.carMovementDetail?.reservationId}</h3>
                                                                </div>
                                                            </td>

                                                        </tr>


                                                    </table>
                                                </td>

                                            </tr>




                                            <tr>
                                                <td>
                                                    <table className="bottom-table">

                                                        <tr>
                                                            <td colSpan="2">
                                                                <h4>Terms & Conditions</h4>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <div dangerouslySetInnerHTML={{ __html: policies }} /> {/* Render policies HTML */}
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td colSpan="2">
                                                                <p>1. Your reservation will be subject to Parking hourly and
                                                                    daily rate as soon as the voucher is expired. The hourly rate is
                                                                    10/hour and the daily rate is $15 for small cars and $18 for
                                                                    SUV/ Trucks per day. </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    2.This facility does NOT allow in/out privileges. You CANNOT enter & exit
                                                                    more than once.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    3. For all Cancelled online vouchers/Reservation customers are
                                                                    required to pay for one day of parking and a $10 service fee.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    4. This facility
                                                                    does not allow online reservation extensions. Additional time must be paid
                                                                    on-site at a regular rate.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    5. Customer is required to take pictures of their
                                                                    vehicles (all sides) at the location during drop-off and also agree that no
                                                                    damage claim can be filed without providing those pictures.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    6. Customer must
                                                                    leave the car key to the attendant and agree that failure to do so may result
                                                                    to towing fees from $75 to $150.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>
                                                                    7. Customer agree that all balance must be paid
                                                                    in full prior to retrieval of vehicle.</p>
                                                            </td>
                                                        </tr> */}

                                                        <tr>
                                                            <td>
                                                                <div class="fix-height"></div>
                                                            </td>
                                                        </tr>



                                                    </table>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>

                                </tr>
                            </table>
                            </div>
                        </TabContext>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default BookingReceipts;