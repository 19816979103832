import React, { useState, useEffect } from 'react'
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill }
  from 'react-icons/bs'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line }
  from 'recharts';
import { image } from "../../src/common/Theme";
import { toast, ToastContainer } from "react-toastify";
import Apiconnection from "../utils/Apiconnection";
import { FaTrash } from 'react-icons/fa'; // Importing the trash icon
import { BorderColor } from '@mui/icons-material';

function Home() {

  const [DashboardCount, setDashboardCount] = useState([]);


  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  useEffect(() => {
    getDashboardCountApi();
    // getNotificationApi();
  }, []);

  const getDashboardCountApi = async () => {
    const payload = {
      parkingGroundId: '1',
    };
    // settableloading(true);
    try {
      const response = await Apiconnection.post('slot-analytics', payload);
      console.log('slot-analytics', response)
      if (response?.data?.status) {
        setDashboardCount(response.data.data);
      } else {
        // toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching Agent list:', error);
      // toast.error('Failed to fetch Agent list.');
    } finally {
      // settableloading(false);
    }
  };

  const getNotificationApi = async () => {
    try {
      const response = await Apiconnection.post('notification-list');
      if (response?.data?.status) {
        const notifications = response.data.data.notifications;
        // Filter out notifications that are already read
        const unreadNotifications = notifications.filter(notification => !notification.isRead);
        unreadNotifications.forEach(notification => {
          displayNotification(notification);
        });
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const displayNotification = (notification) => {
    const toastId = toast.info(
      <div>
        <div>
          <strong>{notification.notiTitle}</strong>
          <p>{notification.notiBody}</p>
        </div>
        <button className='save-btn' onClick={() => handleMarkAsRead(notification.id)}>Mark as Read</button>
        <button className='save-btn mr-2' style={{lineHeight: '20px', padding: '10px 15px !important', marginLeft: '2px', backgroundColor: 'unset !important', border: 'unset !important'}} onClick={() => handleDeleteNotification(notification.id)}>
        <FaTrash style={{ color: 'red', fontSize: '1.5em' }} />
        </button>
      </div>,
      {
        autoClose: false, // Prevent auto close
        closeButton: false, // Disable default close button
      }
    );
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      const payload = { notificationId };
      await Apiconnection.post('notification-delete', payload);
      toast.dismiss(); // Dismiss the toast after deletion
    } catch (error) {
      console.error('Error deleting notification:', error);
      toast.error('Failed to delete notification.');
    }
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      const payload = { notificationId, isRead: true };
      await Apiconnection.post('notification-status-change', payload);
      toast.success('Notification marked as read.');
    } catch (error) {
      console.error('Error marking notification as read:', error);
      toast.error('Failed to mark notification as read.');
    }
  };


  return (
    <main className='main-container'>
      <div className='row'>
        <div className='col-lg-8'>
          <div className='main-cards'>

            <div className='card-inner'>
              <div className='card-left'>
                <h6>Total Slots</h6>
                <h3>{DashboardCount.totalSlots}</h3>
                <p style={{ color: 'white' }}>.</p>
              </div>
              <div className='card-right'>
                <img src={image.chartimg1} alt="" />
              </div>
            </div>

            <div className='card-inner'>
              <div className='card-left'>
                <h6>Booked Slots</h6>
                <h3>{DashboardCount.totalBookedSlots}</h3>
                <p>{DashboardCount.totalOverstaySlots} overstay</p>
              </div>
              <div className='card-right'>
                <img src={image.chartimg2} alt="" />
              </div>
            </div>
            <div className='card-inner'>
              <div className='card-left'>
                <h6>Free Slots</h6>
                <h3>{DashboardCount.totalFreeSlots}</h3>
                <p style={{ color: 'white' }}>.</p>
              </div>
              <div className='card-right'>
                <img src={image.chartimg3} alt="" />
              </div>
            </div>

          </div>
          <div className='charts'>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>



          </div>
        </div>
        <div className='col-lg-4'>
          <div className='payment-info'>
            <img src={image.payments} alt="" />
            <div className='payment-status'>
              <p>Payment Received</p>
              <h5>$ 357699</h5>
            </div>
          </div>
          <div className='booking-sec'>
            <div className='booking-header'>
              <h4>New Bookings</h4>
              <a href="#">VIEW ALL</a>
            </div>
            <div className='booking-box'>
              <div className='booking-user'>
                <h4>AG</h4>
              </div>
              <div className='booking-details'>
                <h5>Anastasya Gordon</h5>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
                <div className='booking-time'>
                  <p>10m</p>
                  <a href='#'><img src={image.action} alt="" /></a>
                </div>
              </div>
            </div>
            <div className='booking-box'>
              <div className='booking-user'>
                <h4>AG</h4>
              </div>
              <div className='booking-details'>
                <h5>Anastasya Gordon</h5>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
                <div className='booking-time'>
                  <p>10m</p>
                  <a href='#'><img src={image.action} alt="" /></a>
                </div>
              </div>
            </div>
            <div className='booking-box'>
              <div className='booking-user'>
                <h4>AG</h4>
              </div>
              <div className='booking-details'>
                <h5>Anastasya Gordon</h5>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
                <div className='booking-time'>
                  <p>10m</p>
                  <a href='#'><img src={image.action} alt="" /></a>
                </div>
              </div>
            </div>
            <div className='booking-box'>
              <div className='booking-user'>
                <h4>AG</h4>
              </div>
              <div className='booking-details'>
                <h5>Anastasya Gordon</h5>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
                <div className='booking-time'>
                  <p>10m</p>
                  <a href='#'><img src={image.action} alt="" /></a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <ToastContainer
        style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          zIndex: '9999',
          maxHeight: '300px',
          overflowY: 'auto',
        }}
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
      /> */}
    </main>
  )
}

export default Home