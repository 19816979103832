import React, { useState, useEffect } from "react";
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { image } from "../../common/Theme";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Modal, Typography, TextField, FormControlLabel, Checkbox, MenuItem, Select, FormControl, InputLabel, FormHelperText, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { addUserData, editUserData, fetchAccessOptions, fetchRoleList, getUserDataApi } from "./api";
import Validation from "../Validation/validation";
import { columns, countryCodes, genderJson, staticData, updateFields } from "./utils";
import { showError } from "../Validation";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Table from "../../component/Table";
import Apiconnection from "../../utils/Apiconnection";
import TableLoader from "../../utils/TableLoader";
import Loader from "../../utils/Loader";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
// import { image } from "../../src/common/Theme";

const UserList = () => {
    const [data, setData] = useState({
        moduleAccess: [],
        profileImage: {},
        roleId: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        dialCode: '',
        phone: '',
        gender: '',
        address: '',
        dob: dayjs(),
    });

    const [tableLoading, setTableLoading] = useState(false);
    const [moduleAccessOptions, setModuleAccessOptions] = useState([]);
    const [editData, setEditData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userImageUrl, setUserImageUrl] = useState(null);
    const [roleList, setRoleList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [errors, setErrors] = useState({});
    const { user } = useSelector((state) => state?.user);
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }
    useEffect(() => {
        getUserData();
        getRoleList();
        getAccessOptions();
    }, []);

    const getUserData = async () => {
        setTableLoading(true);
        try {
            const response = await Apiconnection.post('user-list');
            setTableLoading(false);
            if (response?.data?.status) {
                setUserList(response?.data?.data?.userList);
                setUserImageUrl(response?.data?.data?.userImageUrl);
            }
        } catch (error) {
            setTableLoading(false);
            console.log('error', error);
        }
    };

    const getAccessOptions = async () => {
        try {
            const response = await Apiconnection.post('module-list');
            if (response?.data?.status) {
                setModuleAccessOptions(response?.data?.data);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getRoleList = async () => {
        try {
            const response = await Apiconnection.post('role-list');
            setLoading(false);
            if (response?.data?.status) {
                setRoleList(response?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleModuleAccessChange = (e, id) => {
        const { checked } = e.target;
        setData((prev) => {
            const newModuleAccess = checked
                ? [...prev.moduleAccess, id]
                : prev.moduleAccess.filter((moduleId) => moduleId !== id);
            return { ...prev, moduleAccess: newModuleAccess };
        });
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setData((prev) => ({ ...prev, profileImage: file }));
            setUserImageUrl(URL.createObjectURL(file));
        } else {
            toast.error("Please select a valid image file.");
        }
    };

    const handleOpenModal = () => {
        setOpenModal(true);
        setEditData(null);
        resetForm();
    };

    const resetForm = () => {
        setData({
            moduleAccess: [],
            profileImage: {},
            roleId: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            dialCode: '',
            phone: '',
            gender: '',
            address: '',
            dob: dayjs(),
        });
        setErrors({});
    };

    const validateForm = () => {
        const newErrors = {};
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!data.roleId) newErrors.roleId = "Role is required.";
        if (!data.firstName) newErrors.firstName = "First Name is required.";
        if (!data.lastName) newErrors.lastName = "Last Name is required.";
        if (!data.email) {
            newErrors.email = "Email is required.";
        } else if (!emailPattern.test(data.email)) {
            newErrors.email = "Please enter a valid email address.";
        }
        if (data.password && data.password !== data.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }
        if (data.password && !data.confirmPassword) {
            newErrors.confirmPassword = "Confirm Password is required.";
        }
        if (!data.dialCode) newErrors.dialCode = "Dial Code is required.";
        if (!data.phone) newErrors.phone = "Phone Number is required.";
        if (!data.gender) newErrors.gender = "Gender is required.";
        if (!data.address) newErrors.address = "Address is required.";
        return newErrors;
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        resetForm();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            const payload = new FormData();
            payload.append('id', editData ? editData.id : '');
            payload.append('moduleAccess', JSON.stringify(data.moduleAccess));
            payload.append('profileImage', data.profileImage);
            payload.append('roleId', data.roleId);
            payload.append('firstName', data.firstName);
            payload.append('lastName', data.lastName);
            payload.append('email', data.email);
            if (data.password) {
                payload.append('password', data.password);
            }
            payload.append('dialCode', data.dialCode);
            payload.append('phone', data.phone);
            payload.append('gender', data.gender);
            payload.append('address', data.address);
            payload.append('dob', data.dob ? data.dob.format('YYYY-MM-DD') : null);
            const response = await Apiconnection.post('user-add-update', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.data?.status) {
                toast.success(editData ? "User updated successfully!" : "User added successfully!");
                getUserData();
                handleCloseModal();
            }
        } catch (error) {
            console.log('error', error);
            toast.error(error.response.data.message);
        }
    };

    const handleDeleteUser = async (row) => {
        setLoading(true);
        try {
            const response = await Apiconnection.post('user-soft-delete', { id: row.id });
            if (response.data.status) {
                toast.success("User Deleted Successfully")
                const updatedUserList = userList.filter((item) => item.id !== row.id);
                setUserList(updatedUserList);
            }
        } catch (error) {
            console.error('Error deleting user data:', error);
        } finally {
            setLoading(false);
        }
    };

    const EditHandle = async (id) => {
        let obj = {
            id,
        };
    
        try {
            const response = await Apiconnection.post('user-details', obj);
            if (response.data.status) {
                const userData = response.data.data;
                const dob = userData.dob ? dayjs(userData.dob) : null;
                setData({
                    ...userData,
                    dob,
                    confirmPassword: '', // Reset confirm password for edit
                    moduleAccess: userData.moduleAccess || [],
                });
                setEditData(userData);
                setOpenModal(true);
            }
        } catch (e) { }
    };

    return (
        <div className='grid-container'>
            <Header OpenSidebar={setOpenSidebarToggle} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <div className='user-list'>
                <div className='top-section'>
                    <h3>User List</h3>
                    <Button variant="contained" className="action-btn" onClick={handleOpenModal}>
                        <AddIcon />
                        Add User
                    </Button>
                </div>

                <div className='custom-table table-responsive'>
                    {!tableLoading ?
                        <table className='table '>
                            <thead>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Phone</th>
                                <th>Date of Birth</th>
                                <th>Gender</th>
                                <th>Address</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {userList && userList.length > 0 ?
                                    userList.map((list, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{list?.firstName + ' ' + list?.lastName}</td>
                                                <td><img crossorigin='anonymous' src={list.profileImage ? userImageUrl + list.profileImage : image.userimage} width={list.profileImage ? "30" : "30"} alt="profile" /></td>
                                                <td>{list?.email}</td>
                                                <td>{list?.role?.roleName}</td>
                                                <td>{list?.phone}</td>
                                                <td>{list?.dob}</td>
                                                <td>{list?.gender}</td>
                                                <td>{list?.address}</td>
                                                <td>
                                                    <div className="flx-box">
                                                        <button className="btn btn-sm btn-outline-warning me-2" onClick={() => EditHandle(list?.id)}>
                                                            <i className="fa-solid fa-pen-to-square"></i></button>
                                                        <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteUser(list)}><i className="fa-solid fa-trash"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })

                                    :

                                    <tr>
                                        <td colSpan="4" className='text-center'>No data record</td>
                                    </tr>

                                }

                            </tbody>
                        </table>
                        :
                        <TableLoader />

                    }
                </div>

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="add-user-modal"
                    aria-describedby="add-user-modal-description">
                    <div className="right-open-modal">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" id="add-user-modal">{editData ? 'Edit User' : 'Add User'}</Typography>
                    <IconButton onClick={handleCloseModal} aria-label="close" style={{marginBottom: '15px'}}>
                            <CloseIcon />
                        </IconButton>
                        </div>

                        <form>
                            <FormControl fullWidth error={!!errors?.roleId}>
                                <InputLabel>Role</InputLabel>
                                <Select
                                    label='Role'
                                    value={data.roleId}
                                    onChange={handleChange}
                                    name="roleId"
                                >
                                    {roleList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.roleName}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.roleId}</FormHelperText>
                            </FormControl>

                            <TextField
                                label="First Name"
                                value={data.firstName}
                                onChange={handleChange}
                                name="firstName"
                                error={!!errors?.firstName}
                                helperText={errors?.firstName}
                                required
                            />

                            <TextField
                                label="Last Name"
                                value={data.lastName}
                                onChange={handleChange}
                                name="lastName"
                                error={!!errors?.lastName}
                                helperText={errors?.lastName}
                                required
                            />

                            <TextField
                                label="Email"
                                value={data.email}
                                onChange={handleChange}
                                name="email"
                                error={!!errors?.email}
                                helperText={errors?.email}
                                required
                            />

                            <TextField
                                label="Password"
                                value={data.password}
                                onChange={handleChange}
                                name="password"
                                error={!!errors?.password}
                                helperText={errors?.password}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => setShowPassword((prev) => !prev)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    ),
                                }}
                                required
                                style={{marginBottom: '1px'}}
                            />
                            {editData && (
                            <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic', fontWeight: '600', marginBottom: '10px', marginTop: '-25px', fontSize: '11px' }}>
                                ( Existing password can't be visible, please enter a new password to update. )
                            </Typography>
                            )}

                            <TextField
                                label="Confirm Password"
                                value={data.confirmPassword}
                                onChange={handleChange}
                                name="confirmPassword"
                                error={!!errors?.confirmPassword}
                                helperText={errors?.confirmPassword}
                                type={showConfirmPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => setShowConfirmPassword((prev) => !prev)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    ),
                                }}
                                required
                            />

                            <FormControl fullWidth error={errors?.dialCode}>
                                <InputLabel>Dialcode</InputLabel>
                                <Select
                                    label='Dialcode'
                                    value={data.dialCode}
                                    onChange={handleChange}
                                    name="dialCode"
                                >
                                    {countryCodes.map((countryCode) => (
                                        <MenuItem key={countryCode.value} value={countryCode.value}>{countryCode.value}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.dialCode}</FormHelperText>
                            </FormControl>

                            <TextField
                                label="Mobile Number"
                                value={data.phone}
                                onChange={handleChange}
                                name="phone"
                                inputProps={{
                                    maxLength: 10,
                                    pattern: "[0-9]*",
                                    onInput: (e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }
                                }}
                                error={!!errors?.phone}
                                helperText={errors?.phone}
                                required
                            />

                            <FormControl fullWidth error={errors?.gender}>
                                <InputLabel>Gender</InputLabel>
                                <Select
                                    label='Gender'
                                    value={data.gender}
                                    onChange={handleChange}
                                    name="gender"
                                >
                                    {genderJson.map((gender) => (
                                        <MenuItem key={gender.value} value={gender.value}>{gender.value}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.gender}</FormHelperText>
                            </FormControl>

                            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Date of Birth"
                                    value={data.dob ? dayjs(data.dob) : null}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setData((prev) => ({ ...prev, dob: newValue }));
                                        }
                                    }}
                                    maxDate={dayjs()}
                                    renderInput={(params) => (
                                        <TextField {...params} error={!!errors?.dob} helperText={errors?.dob} />
                                    )}
                                />
                            </LocalizationProvider>

                            <TextField
                                label="Address"
                                value={data.address}
                                onChange={handleChange}
                                name="address"
                                error={!!errors?.address}
                                helperText={errors?.address}
                                required
                            />

                            <input
                                type="file"
                                onChange={handleProfileImageChange}
                                name="profileImage"
                                style={{ marginBottom: '20px' }}
                            />
                                        {userImageUrl && (
                <div style={{ marginBottom: '20px' }}>
                    <img src={userImageUrl} alt="Profile Preview" width="100" />
                </div>
            )}

                            <div>
                                <h4>Select a section for access:</h4>
                                {moduleAccessOptions.map((row) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={row?.id.toString()}
                                                checked={data.moduleAccess?.includes(row?.id)}
                                                onChange={(e) => handleModuleAccessChange(e, row?.id)}
                                                name="moduleAccess"
                                            />
                                        }
                                        label={row?.moduleName}
                                    />
                                ))}
                                {showError(errors?.moduleName)}
                            </div>

                            <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </form>
                    </div>
                </Modal>

            </div>
        </div>
    );
};

export default UserList;