import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { Grid, Box, TextField, Typography, Button, IconButton, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { image } from "../../../src/common/Theme";
import { DateRange } from 'react-date-range';
import { countryCodes } from "../dashboard/utils";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import Apiconnection from '../../utils/Apiconnection';
import { toast } from 'react-toastify';

const AddNewBooking = () => {

  const location = useLocation();
  const bookingPayload = location.state?.updatedPayload || {}; // Access updatedPayload correctly
  console.log("bookingPayload", bookingPayload)

  const bookingDetails = location.state; // Access the passed booking details
  console.log("bookingDetails", bookingDetails)


  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [vechileList, setVechileList] = useState([]);
  const [carTypeId, setCarTypeId] = useState('');
  const [vehicleTypeId, setVehicleTypeId] = useState(bookingDetails?.vehicleTypeId || bookingPayload?.vechiletypeId || '');
  const [firstName, setfirstname] = useState(bookingDetails?.user?.firstName || bookingPayload?.firstName || '');
  const [lastName, setlastname] = useState(bookingDetails?.user?.lastName || bookingPayload?.lastName || '');
  const [dialCode, setDialCode] = useState(bookingPayload?.user?.dialCode || '+1');
  const [mobileNumber, setMobileNumber] = useState(bookingDetails?.user?.phone || bookingPayload?.mobileNumber || '');
  const [plateNumber, setPlateNumber] = useState(bookingDetails?.plateNumber || bookingPayload?.plateNumber || '');
  const [confirmPlateNumber, setConfirmPlateNumber] = useState(bookingPayload?.confirmPlateNumber || '');
  const [debouncedConfirmPlateNumber, setDebouncedConfirmPlateNumber] = useState('');
  const [plateNumberChanged, setPlateNumberChanged] = useState(false);
  const [confirmPlateNumberFocused, setConfirmPlateNumberFocused] = useState(false);
  const [reservationId, setReservationId] = useState(bookingPayload?.reservationId || '');
  const [confirmReservationId, setConfirmReservationId] = useState(bookingPayload?.cnfReservationId || '');
  const [dailyParkingRate, setDailyParkingRate] = useState('');
  const [oversizeRate, setoversizeRate] = useState('');
  const [customerExist, setCustomerExist] = useState(false);
  const [slotName, setSlotName] = useState(bookingDetails?.slot?.slotName || bookingPayload?.slotName || '');
  const [slotId, setSlotId] = useState(bookingDetails?.slot?.id || bookingPayload?.slotId || '');
  const [slotAvailable, setSlotAvailable] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [carType, setCarType] = useState(bookingDetails?.carType ||bookingPayload?.carType || '');
  const [startDateTime, setStartDateTime] = useState(() => {
    if (bookingDetails?.fromDate && bookingDetails?.selectedCheckInTime) {
      return dayjs(bookingDetails.fromDate + ' ' + bookingDetails.selectedCheckInTime);
    } else if (bookingPayload?.startDateTime) {
      return dayjs(bookingPayload.startDateTime);
    } else {
      return dayjs(); // Current system date and time
    }
  });
  
  const [endDateTime, setEndDateTime] = useState(() => {
    if (bookingDetails?.toDate && bookingDetails?.selectedCheckOutTime) {
      return dayjs(bookingDetails.toDate + ' ' + bookingDetails.selectedCheckOutTime);
    } else if (bookingPayload?.endDateTime) {
      return dayjs(bookingPayload.endDateTime);
    } else {
      return dayjs(); // Current system date and time
    }
  });
  const [numPassengers, setNumPassengers] = useState(bookingPayload?.numPassengers || 4);
  const [email, setEmail] = useState(bookingDetails?.user?.email || bookingPayload?.email || '');
  const [vehicleList, setvehicleList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [checkinDate, setCheckinDate] = useState('');
  const [checkoutDate, setCheckoutDate] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [isOnsiteChargeApplicable, setIsOnsiteChargeApplicable] = useState(false);
  const [onsiteAmount, setonsiteAmount] = useState(bookingPayload?.onSiteChargeForVendor || '');
  const [vendorId, setVendorId] = useState(location.state?.vendorId || bookingPayload?.vendorId);
  const today = new Date();

  console.log('startDateTime', startDateTime, 'endDateTime', endDateTime, 'checkinDate', checkinDate, 'checkoutDate', checkoutDate)


  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [state, setState] = useState([
    {
      startDate: bookingDetails?.bookingStatus === "Booked"
        ? dayjs(bookingDetails.fromDate + ' ' + bookingDetails.selectedCheckInTime).toDate() 
        : (bookingPayload?.fromDate ? dayjs(bookingPayload.fromDate).toDate() : new Date()),
      endDate: bookingDetails?.bookingStatus === "Booked"
        ? dayjs(bookingDetails.toDate + ' ' + bookingDetails.selectedCheckOutTime).toDate() 
        : (bookingPayload?.toDate ? dayjs(bookingPayload.toDate).toDate() : new Date()),
      key: 'selection'
    }
  ]);

  const navigate = useNavigate();
  // const vendorId = location.state?.vendorId;
  // console.log('vendorId', vendorId)

  const [data, setData] = useState({
    customerName: bookingPayload.customerName || '',
    mobileNumber: bookingPayload.mobileNumber || '',
    plateNumber: bookingPayload.plateNumber || '',
    confirmPlateNumber: bookingPayload.confirmPlateNumber || '',
    carType: bookingPayload.carType || '',
    startDateTime: bookingPayload.startDateTime ? dayjs(bookingPayload.startDateTime) : null,
    endDateTime: bookingPayload.endDateTime ? dayjs(bookingPayload.endDateTime) : null,
    numPassengers: bookingPayload.numPassengers || 1,
  });

  useEffect(() => {
    // Enable the submit button if slotName has a value
    setSubmitDisabled(!slotName.trim());
  }, [slotName]);

  // Check if submit should be disabled based on other relevant fields
  useEffect(() => {
    const checkSubmitDisabled = () => {
      if (firstName && lastName && mobileNumber && plateNumber && confirmPlateNumber && slotName) {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
    };

    checkSubmitDisabled();
  }, [firstName, lastName, mobileNumber, plateNumber, confirmPlateNumber, slotName]);

  useEffect(() => {
    console.log('Booking Payload:', bookingPayload);
  }, [bookingPayload]);

  useEffect(() => {
    // Check slot availability when component mounts or when relevant state changes
    const checkSlotAvailability = async () => {
      if (slotName && startDateTime && endDateTime) {
        const checkinDate = dayjs(startDateTime).format("YYYY-MM-DD");
        const checkoutDate = dayjs(endDateTime).format("YYYY-MM-DD");
        const startTime = dayjs(startDateTime).format("HH:mm");
        const endTime = dayjs(endDateTime).format("HH:mm");

        const payload = {
          checkinDate,
          startTime,
          checkoutDate,
          endTime,
        };

        try {
          const response = await Apiconnection.post("decide-slot", payload);
          if (response.data.status) {
            setSlotAvailable(true);
          } else {
            setSlotAvailable(false);
            toast.error("Slot not available");
          }
        } catch (error) {
          console.error("Error checking slot availability:", error);
          setSlotAvailable(false);
        }
      }
    };

    checkSlotAvailability();
  }, [slotName, startDateTime, endDateTime]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(prev => !prev);
  };

  const handlePassengerIncrease = () => {
    setNumPassengers(prev => prev + 1);
  };

  const handlePassengerDecrease = () => {
    if (numPassengers > 1) {
      setNumPassengers(prev => prev - 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMobileNumberChange = (e) => {
    const mobileNumber = e.target.value;
    setMobileNumber(mobileNumber);
    if (mobileNumber.length === 10) {
      checkCustomerExist(mobileNumber);
    }
  };

  const checkCustomerExist = async (mobileNumber) => {
    if (mobileNumber.length !== 10) {
      return;
    }

    const payload = {
      customerMobile: mobileNumber,
    };
    try {
      const response = await Apiconnection.post('customer-exist-check', payload);
      if (response.data.status) {
        setCustomerExist(true);
        setCustomerId(response.data.data.id);
        setfirstname(response.data.data.firstName);
        setlastname(response.data.data.lastName);
        setEmail(response.data.data.email);
        setDialCode(response.data.data.dialCode);
        setMobileNumber(response.data.data.phone);
        toast.success(response.data.message);
      } else {
        setCustomerExist(false);
        setCustomerId('');
        setfirstname('');
        setlastname('');
        setEmail('');
        setDialCode('+1');
        // setMobileNumber('');
        toast.info(response.data.message);
      }
    } catch (error) {
      console.error('Error checking customer exist:', error);
    }
  };

  const handleConfirmPlateNumberChange = (e) => {
    const confirmPlateNumber = e.target.value;
    setConfirmPlateNumber(confirmPlateNumber);
  };

  const handlePlateNumberChange = (e) => {
    const plateNumber = e.target.value;
    setPlateNumber(plateNumber);
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setDebouncedConfirmPlateNumber(confirmPlateNumber);
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [confirmPlateNumber]);

  // const handleConfirmPlateNumberChange = (e) => {
  //   const confirmPlateNumber = e.target.value;
  //   setConfirmPlateNumber(confirmPlateNumber);
  //   setPlateNumberChanged(false);
  // };

  // const handleConfirmPlateNumberFocus = () => {
  //   setConfirmPlateNumberFocused(true);
  // };

  // const handleConfirmPlateNumberBlur = () => {
  //   setConfirmPlateNumberFocused(false);
  //   if (debouncedConfirmPlateNumber && debouncedConfirmPlateNumber !== plateNumber) {
  //     toast.error('Plate number and confirm plate number do not match!');
  //   }
  // };

  // const handlePlateNumberChange = (e) => {
  //   const plateNumber = e.target.value;
  //   setPlateNumber(plateNumber);
  //   setPlateNumberChanged(true);
  // };

  // useEffect(() => {
  //   if (debouncedConfirmPlateNumber && debouncedConfirmPlateNumber !== plateNumber && !plateNumberChanged && !confirmPlateNumberFocused) {
  //     toast.error('Plate number and confirm plate number do not match!');
  //   }
  // }, [debouncedConfirmPlateNumber, plateNumber, plateNumberChanged, confirmPlateNumberFocused]);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const response = await Apiconnection.post('vendor-details', { id: vendorId });
        if (response.data.status) {
          const vendorData = response.data.data;
          setIsOnsiteChargeApplicable(vendorData.isOnsiteChargeApplicable);
          // setVendorId(vendorData.id);
        }
      } catch (error) {
        console.error('Error fetching vendor details:', error);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate Customer Name
    if (!firstName.trim()) {
      newErrors.firstName = "First Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(firstName)) {
      newErrors.firstName = "First Name can only contain letters and spaces";
    }

    if (!lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(lastName)) {
      newErrors.lastName = "Last Name can only contain letters and spaces";
    }

    // Validate Email
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailPattern.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Validate Mobile Number
    if (!mobileNumber.toString().trim()) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!/^\+?[1-9]\d{1,14}$/.test(mobileNumber.toString())) {
      newErrors.mobileNumber = "Mobile Number must be in a valid format";
    }

    // Validate Plate Number
    if (!plateNumber.trim()) newErrors.plateNumber = "Plate Number is required";
    if (plateNumber !== confirmPlateNumber) newErrors.confirmPlateNumber = "Plate Number and Confirm Plate Number do not match";

    // Validate Vehicle Selection
    if (!vehicleTypeId) {
      newErrors.vehicleTypeId = "Please select a vehicle type";
    }

    if (vendorId) {
      if (!reservationId.trim()) {
        newErrors.reservationId = "Reservation ID is required";
      }

      if (reservationId !== confirmReservationId) {
        newErrors.confirmReservationId = "Reservation ID and Confirm Reservation ID do not match";
      }
    }

    return newErrors;
  };

  useEffect(() => {
    // getVechileListApi();
    // Pre-select the vehicle based on bookingPayload
    if (vehicleTypeId) {
      const selectedCar = vehicleList.find(car => car.vehicleTypeId === vehicleTypeId);
      if (selectedCar) {
        setSelectedCar(selectedCar);
        setDailyParkingRate(selectedCar.dailyParkingRate);
        setoversizeRate(selectedCar.oversizeRate);
        setCarType(selectedCar.typeName);
      }
    }
  }, [vehicleTypeId, vehicleList]);

  useEffect(() => {
    getVechileListApi();
  }, [])

  const vehicleImages = {
    Truck: image.truck,
    Sedan: image.car,
    'Small SUV': image.car,
    'Large SUV': image.car,
  };

  const getVechileListApi = async () => {
    setLoading(true);
    try {
      const response = await Apiconnection.post('price-chart-list');
      if (response?.data?.status) {
        // console.log('response?.data?.data', response.data.data);
        // Map the response data to set the vehicle list
        const vehicleData = response.data.data.map(vehicle => ({
          id: vehicle.id,
          vehicleTypeId: vehicle.vehicleTypeId,
          dailyParkingRate: vehicle.dailyParkingRate,
          oversizeRate: vehicle.overSizeRate,
          typeName: vehicle.vehicleType.typeName // Get the vehicle type name
        }));
        setvehicleList(vehicleData); // Set the formatted vehicle list
      }
    } catch (error) {
      console.error('Error fetching Vehicle List data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCarSelect = (car) => {
    // console.log(car)
    setVehicleTypeId(car.vehicleTypeId);
    setSelectedCar(car);
    setCarType(car.typeName);
    setDailyParkingRate(car.dailyParkingRate);
    if (vendorId) {
      setoversizeRate(car.oversizeRate);
    } else {
      setoversizeRate(car.dailyParkingRate);
    }
  };

  const handleCarTypeChange = (e) => {
    const selectedCarType = e.target.value;
    const selectedCarTypeId = vechileList.find((car) => car.typeName === selectedCarType).id;
    setCarType(selectedCarType);
    setCarTypeId(selectedCarTypeId);
  };


  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    // Update the state with the new date range
    setState([{
      startDate,
      endDate,
      key: 'selection'
    }]);

    // Set the start and end date times to the new values
    const currentTime = dayjs();
    setStartDateTime(dayjs(startDate).hour(currentTime.hour()).minute(currentTime.minute()));
    setEndDateTime(dayjs(endDate).hour(currentTime.hour()).minute(currentTime.minute()));

    // Call handleSlotCheck with the updated values
    const checkinDate = dayjs(startDate).format("YYYY-MM-DD");
    const checkoutDate = dayjs(endDate).format("YYYY-MM-DD");
    const startTime = dayjs(startDate).format("HH:mm");
    const endTime = dayjs(endDate).format("HH:mm");

    handleSlotCheck(checkinDate, checkoutDate, startTime, endTime);
  };

  const handleTimeChange = (newValue, type) => {
    if (type === "start") {
      setStartDateTime(newValue);
    } else {
      setEndDateTime(newValue);
    }
    const checkinDate = state[0].startDate ? dayjs(state[0].startDate).format("YYYY-MM-DD") : "";
    const checkoutDate = state[0].endDate ? dayjs(state[0].endDate).format("YYYY-MM-DD") : "";
    const startTime = type === "start" ? dayjs(newValue).format("HH:mm") : startDateTime ? dayjs(startDateTime).format("HH:mm") : "00:00";
    const endTime = type === "end" ? dayjs(newValue).format("HH:mm") : endDateTime ? dayjs(endDateTime).format("HH:mm") : "00:00";

    handleSlotCheck(checkinDate, checkoutDate, startTime, endTime);
  };

  const handleSlotCheck = async (checkinDate, checkoutDate, startTime, endTime) => {
    const payload = {
      checkinDate,
      startTime,
      checkoutDate,
      endTime,
    };
    try {
      const response = await Apiconnection.post("decide-slot", payload);
      if (response.data.status) {
        setSlotName(response.data.data.bookedSlot.slotName);
        setSlotId(response.data.data.bookedSlot.id);
        setSlotAvailable(true);
        setSubmitDisabled(false);
        toast.success("Slot available");
      } else {
        setSlotAvailable(false);
        setSubmitDisabled(true);
        toast.error("Slot not available");
      }
    } catch (error) {
      console.error("Error checking slot availability:", error);
      setSlotAvailable(false);
      setSubmitDisabled(true);
    }
  };

  const handleSlotNameChange = async (e) => {
    const slotName = e.target.value;
    setSlotName(slotName);

    // Check if slot name has a value and update submitDisabled accordingly
    if (slotName.trim()) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }

    const payload = {
      slotName: slotName,
    };
    try {
      const response = await Apiconnection.post('check-slot-validity', payload);
      if (response.data.status) {
        setSlotAvailable(true);
        setSlotId(response.data.data.foundSlot.id);
        toast.success(response.data.message);
      } else {
        setSlotAvailable(false);
        setSlotId('');
        setSubmitDisabled(true); // Disable submit button
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error checking slot availability:', error);
      setSubmitDisabled(true); // Disable submit button
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (validateForm()) {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      Object.keys(newErrors).forEach((key) => {
        toast.error(newErrors[key]);
      });
      return;
    }

    if (!firstName) {
      toast.error('Please enter First Name!');
      return;
    }

    if (!lastName) {
      toast.error('Please enter Last Name!');
      return;
    }

    if (!startDateTime) {
      toast.error('Please select a start date and time!');
      return;
    }

    if (!endDateTime) {
      toast.error('Please select an end date and time!');
      return;
    }

    if (!email) {
      toast.error('Please enter an email address!');
      return;
    }

    if (!mobileNumber) {
      toast.error('Please enter a mobile number!');
      return;
    }

    if (!plateNumber) {
      toast.error('Please enter a plate number!');
      return;
    }

    if (!confirmPlateNumber) {
      toast.error('Please enter a confirm plate number!');
      return;
    }

    if (plateNumber !== confirmPlateNumber) {
      toast.error('Plate number and confirm plate number do not match!');
      return;
    }

    if (!slotAvailable) {
      toast.error('Slot is not available!');
      return;
    }

    if (vendorId) {
      if (!reservationId) {
        toast.error('Please enter a Reservation ID!');
        return;
      }

      if (!confirmReservationId) {
        toast.error('Please enter a Confirm Reservation ID!');
        return;
      }

      if (reservationId !== confirmReservationId) {
        toast.error('Reservation ID and Confirm Reservation ID do not match!');
        return;
      }
    }


    // Check if the form is in edit mode
    const isEditMode = Boolean(location.state?.isEditMode); // Assuming you pass this flag when navigating

    // Create the updated payload
    const updatedPayload = {
      ...bookingPayload,
      ...data, // Merge existing payload with updated data
    };

    let payload = {
      bookingPlatform: "adminPanel",
      logInType: customerExist ? "Customer" : "Guest",
      slotId: slotId,
      slotName: slotName,
      firstName: firstName,
      lastName: lastName,
      onSiteChargeForVendor: onsiteAmount,
      vehicleTypeId: vehicleTypeId,
      email: email,
      dialCode: dialCode,
      phone: mobileNumber,
      plateNumber: plateNumber,
      fromDate: startDateTime ? dayjs(startDateTime).format("YYYY-MM-DD") : "",
      selectedCheckInTime: startDateTime ? dayjs(startDateTime).format("HH:mm") : "",
      toDate: endDateTime ? dayjs(endDateTime).format("YYYY-MM-DD") : "",
      selectedCheckOutTime: endDateTime ? dayjs(endDateTime).format("HH:mm") : "",
      passengerCount: numPassengers,
      customerId: customerId,
      vendorId: vendorId,
      actualvehicleTypeId: vehicleTypeId,
      actualFromDate: startDateTime ? dayjs(startDateTime).format("YYYY-MM-DD") : "",
      actualCheckInTime: startDateTime ? dayjs(startDateTime).format("HH:mm") : "",
      actualToDate: endDateTime ? dayjs(endDateTime).format("YYYY-MM-DD") : "",
      actualCheckOutTime: endDateTime ? dayjs(endDateTime).format("HH:mm") : "",
      carType: carType,
      dailyParkingRate,
      oversizeRate,
      reservationId: reservationId,
    };

    if (vendorId) {
      payload.reservationId = reservationId;
    }

    // If not in edit mode, use the original bookingPayload values
    const finalPayload = isEditMode ? updatedPayload : payload;

    setLoading(true);

    const combinedState = {
      bookingDetails: bookingDetails, // Assuming bookingDetails is defined in your component
      payload: finalPayload,
    };

    navigate('/booking-details', { state: combinedState });
    console.log("finalPayload", combinedState)


    // navigate('/booking-details', { state: { bookingDetails: finalPayload, bookingResponse } });

    // try {
    //   const response = await Apiconnection.post('booking-request-create', payload);
    //   if (response?.data?.status) {
    //     const bookingRequestId = response.data.data.id;
    //     const calculateBookingChargesPayload = {
    //       bookingRequestId,
    //       vehicleTypeId,
    //       passengerCount: numPassengers,
    //       checkinDate: startDateTime ? dayjs(startDateTime).format("YYYY-MM-DD") : "",
    //       startTime: startDateTime ? dayjs(startDateTime).format("HH:mm") : "00:00",
    //       checkoutDate: endDateTime ? dayjs(endDateTime).format("YYYY-MM-DD") : "",
    //       endTime: endDateTime ? dayjs(endDateTime).format("HH:mm") : "00:00",
    //     };

    //     const calculateBookingChargesResponse = await Apiconnection.post('calculate-booking-charges-before-booking', calculateBookingChargesPayload);
    //     if (calculateBookingChargesResponse?.data?.status) {
    //       toast.success('Added successfully!');
    //       const bookingDetails = {
    //         invoiceCreate: calculateBookingChargesResponse.data.data.invoiceCreate,
    //         bookingRequestDetail: calculateBookingChargesResponse.data.data.bookingRequestDetail,
    //       };
    //       navigate('/booking-details', { state: bookingDetails });
    //     } else {
    //       toast.error('Failed to calculate booking charges!');
    //     }
    //   } else {
    //     toast.error('Failed to create booking!');
    //   }

    // } catch (error) {
    //   console.error('Error adding vehicle data:', error);
    //   toast.error('Failed to create booking!');
    // } finally {
    //   setLoading(false);
    // }
    // }
  };

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <div className='inner-container'>
        <div className="new-booking-sec">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={7} md={7}>
                <Typography variant="h5">Add New Booking</Typography>

                {/* {customerExist && ( */}

                <div className='d-flex cus-gap'>
                  <div style={{ width: '30%' }}>
                    <InputLabel style={{ fontSize: '12px' }}>Dialcode</InputLabel>
                    <Select variant="outlined" fullWidth label="Dialcode" margin="normal" id="dialCode" value={dialCode} onChange={(e) => setDialCode(e.target.value)} name="dialCode" >
                      {countryCodes.map((countryCode) => (
                        <MenuItem value={countryCode.value}>{countryCode.value} </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors?.dialCode}</FormHelperText>
                  </div>
                  <div style={{ width: '70%' }}>
                    <TextField
                      sx={{
                        height: '28px',
                      }}
                      label="Mobile Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 10 }}
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                      required
                    />
                  </div>
                </div>


                <div className='d-flex cus-gap'>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setfirstname(e.target.value)}
                    required
                  />

                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setlastname(e.target.value)}
                    required
                  />
                </div>
                {/* )} */}

                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="email"
                  value={email}
                  error={!!errors?.email}
                  helperText={errors?.email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <div className='d-flex cus-gap'>

                  <TextField
                    label="Plate Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="plateNumber"
                    value={plateNumber}
                    onChange={handlePlateNumberChange}
                    required
                  />

                  <TextField
                    label="Confirm Plate Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="confirmPlateNumber"
                    value={confirmPlateNumber}
                    onChange={handleConfirmPlateNumberChange}
                    required
                  />
                </div>

                {vendorId && (
                  <div className='d-flex cus-gap'>
                    <TextField
                      label="Reservation ID"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={reservationId}
                      onChange={(e) => setReservationId(e.target.value)}
                      required
                    />

                    <TextField
                      label="Confirm Reservation ID"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={confirmReservationId}
                      onChange={(e) => setConfirmReservationId(e.target.value)}
                      required
                    />
                  </div>
                )}


                <div className='d-flex cus-gap'>
                  <TextField
                    label="Slot Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={slotName}
                    onChange={handleSlotNameChange}
                    required
                  />

                  {isOnsiteChargeApplicable && (
                    <TextField
                      label="Onsite Applicable Charge"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="onsiteAmount"
                      value={onsiteAmount}
                      // onChange={(e) => setonsiteAmount(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setonsiteAmount(value);
                        }
                      }}
                      required
                    />
                  )}
                </div>

                <Box display="flex" alignItems="center" margin="normal" className="passenger-sec">
                  <div className='passenger-add'>
                    <Typography variant="body1">No. of Passengers:</Typography>
                    <span>Additional Charges will apply on Passengers above 4.</span>
                  </div>
                  <div className='cus-add-btn'>
                    <IconButton onClick={handlePassengerDecrease}>
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="body1" style={{ margin: '0 10px' }}>{numPassengers}</Typography>
                    <IconButton onClick={handlePassengerIncrease}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </Box>
                <div className='car-select-box'>
                  <h5>Select Car Type</h5>
                  <div className='select-car-type '>
                    {vehicleList.map((car) => (
                      <div key={car.id} className={`select-car ${selectedCar && selectedCar.id === car.id ? 'selected' : ''}`} onClick={() => handleCarSelect(car)}>
                        <img src={vehicleImages[car.typeName] || image.car} alt={car.typeName} />
                        <p>{car.typeName}</p>
                        {selectedCar && selectedCar.id === car.id && (
                          <div className='selected-car'></div>
                        )}
                      </div>
                    ))}
                  </div>
                  {errors.vehicleTypeId && (
                    <FormHelperText error={true}>{errors.vehicleTypeId}</FormHelperText>
                  )}
                </div>
              </Grid>
              <Grid item lg={5} md={5}>
                <div className='select-date'>
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    minDate={today}
                  />
                  {errors.dateRange && <Typography color="error">{errors.dateRange}</Typography>}
                  <div className='select-time d-flex cus-gap'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Start Time"
                        fullWidth
                        value={startDateTime ? dayjs(startDateTime) : dayjs()}
                        onChange={(newValue) => handleTimeChange(newValue, "start")}
                        ampm={false}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="End Time"
                        fullWidth
                        value={endDateTime ? dayjs(endDateTime) : dayjs()}
                        onChange={(newValue) => handleTimeChange(newValue, "end")}
                        ampm={false}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <Box display="flex" justifyContent="flex-end">
                  <Button className='cancel-btn'  onClick={() => {
                    navigate('/bookings');
                    }}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary" disabled={submitDisabled}>
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewBooking;
